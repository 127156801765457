.ButtonContainer{
    margin-top: 50px;
    text-align: center;
}

.ProductContainer{
    width: 90%;
    margin: 0 auto;
    max-width: 600px;
    padding: 50px 0;
    box-sizing: border-box;
}

.Title{
    font-family: CooperBT;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    color: #252525;
}

.Error{
    color: red;
    text-align: center;
    margin-top: 20px;
}

.CenterText{
    margin-top:50px;
    text-align: center;
}

.LegalText{
    text-align: center;
    margin-top: 50px;
    font-size: 10px;
    color: #333;
}

.Description{
    text-align: center;
    font-size: 18px;
    font-family: ModernEra;
    margin: 50px 0;
}