.Button {
  display: inline-block;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  font-family: ModernEra;
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  align-items: center;
  text-align: center;
  padding: 15px 30px;
  text-decoration: none;
  cursor: pointer;
}

.Inverted {
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #0752f9;
  background-color: #fff;
  font-family: ModernEraBold;
  font-size: 15px;
  color: #0752f9;
  align-items: center;
  text-align: center;
  padding: 15px 30px;
  text-decoration: none;
  cursor: pointer;
}
