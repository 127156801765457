.Product{
    display: flex;
    border: solid 1px #ccc;
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 15px;
    justify-content: space-between;
    flex-direction: column;
}

.Picture{
    text-align: center;
    min-height: 100px;
    min-width: 100px;
    margin-right: 15px;
    box-sizing: border-box;
    overflow: hidden;
}

.Picture img{
    height: 100px;
}

.Container{
    /* width: 80%; */
    display: flex;
    align-items: center;
}

.Details{
    padding-top: 15px;
    display: flex;
    justify-content: space-around;
}


.Details input{
    width: 100%;
    font-size: 20px;
}  

.ProductTitle{
    font-family: ModernEra,serif;
    font-weight: bold;
    font-size: 24px;
}

.Subtitle{
    font-family: ModernEra,serif;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
    color: #2a2a2a;
}

.Price{
    font-family: ModernEra;
    font-size: 20px;
    font-weight: bold;
    color: #99bffc;
    margin-bottom: 15px;
}

.Desc{
    font-family: ModernEra;
    font-size: 16px;
    color: #6d7278;
    font-weight: 400;
}