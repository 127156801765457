.FooterSocial{
    background-color: #000;
    height: 120px;
}

.FooterSocialFlex{
    display: flex;
    flex-direction: column;
}

.Social{
    display: flex;
    padding: 20px;
    justify-content: space-around;
}

.SocialIcon{
    width: 20px;
    height: 20px;
}

.FooterLegend{
    font-family: 'ModernEraMono';
    font-size: 15px;
    color: #525252;
    min-height: 1rem;
    text-align: center;
}

@media (min-width: 900px) {
    .FooterSocial{
        height: 60px;
    }
    .FooterSocialFlex{
        flex-direction: row-reverse;
        align-items: center;
        padding-bottom: 0px;
    }

    .FooterLegend{
        width: 80%;
    }

    .Social{
        width: 20%;
    }
}
