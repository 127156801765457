.Logo img {
  width: 144px;
}

.MenuContent {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LeftContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.RightContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
}

@media (min-width: 951px) {
  .MainContainer {
    background-color: white;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 80px;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
  }

  .SlideMenuContainer {
    display: none;
  }
}

@media (max-width: 950px) {
  .MainContainer {
    background-color: white;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 80px;
    z-index: 10;
    position: absolute;
  }

  .SlideMenuContainer {
    display: flex;
    height: 80px;
  }

  .LinkHome {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .Title {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  .SlideMenu {
    cursor: pointer;
    padding-right: 40px;
  }

  .Logo {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 20px;
  }

  .MenuContent {
    display: none;
  }
}

.MenuIcon {
  top: 25px;
  width: 20px;
  height: 35px;
  position: absolute;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.MenuIcon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: black;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.MenuIcon span:nth-child(1) {
  top: 4px;
}

.MenuIcon span:nth-child(2) {
  top: 14px;
}

.MenuIcon span:nth-child(3) {
  top: 24px;
}

.MenuIcon.Open span:nth-child(1) {
  top: 13px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.MenuIcon.Open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.MenuIcon.Open span:nth-child(3) {
  top: 13px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.Fixed {
  position: fixed;
}
